<template>
	<div class="shippingTemplates">
		<div class="header">
			<buttonPermissions :datas="'addPostage'">
				<el-button type="primary" @click="addTemptet({})">添加</el-button>
			</buttonPermissions>

			<el-button type="text" @click="showTemplate">
				<span v-show='!isShowTemplate'>展示运费模板详情<i class="el-icon-arrow-right"></i></span>
				<span v-show='isShowTemplate'>隐藏运费模板详情<i class="el-icon-arrow-down"></i></span>
			</el-button>
		</div>
		<el-collapse v-model="opened" class='template-collapse' @change="handleChange">
			<el-collapse-item class="template-collapse-item"  :name="item.Id" v-for="item in allData"  v-if="allData&&allData.length" :key="item.Id">
				
						<div slot="title" class="template-title">
							<span>{{item.TemplateName}} </span>

							<div style="padding: 3px 0;margin-right:20px" class="text">
								<span>最后编辑时间：{{item.UpdateTime}}</span>
								<buttonPermissions :datas="'editPostage'">
									<span style="margin:0 15px;cursor: pointer;color:#0000FF" @click.stop="addTemptet(item)">修改</span>
								</buttonPermissions>
								<buttonPermissions :datas="'deletePostage'">
									<span style="color:red;cursor: pointer;" @click.stop="delecTemplate(item)" v-if="!item.IsUsing">删除</span>
								</buttonPermissions>
							</div>

						</div>
						
					<el-card class="box-card">
						<template>
							<el-table :data="item.DetailList" border style="width: 100%">

								<el-table-column prop="name" label="可配送区域"  :key="1">
									<template slot-scope="scope">
										<el-tag :key="tag" v-for="(tag,index1) in scope.row.AreaNames" style="margin:0 10px 10px 10px"
										:disable-transitions="false">
											{{tag}}
										</el-tag>
									</template>
								</el-table-column>

								<el-table-column prop="FirstCountToNumber" label="首件(个)" :key="2" v-if="!item.FreightTemplateType"></el-table-column>
								<el-table-column prop="FirstMoneyToNumber" label="运费(元)" :key="3" v-if="!item.FreightTemplateType"></el-table-column>
								<el-table-column prop="ContinueCountToNumber" label="续件(个)" :key="4" v-if="!item.FreightTemplateType"></el-table-column>
								<el-table-column prop="ContinueMoneyToNumber" label="续费(元)" :key="5" v-if="!item.FreightTemplateType"></el-table-column>

								<el-table-column prop="FirstWeightToNumber" label="首重(KG)" :key="6" v-if="item.FreightTemplateType"></el-table-column>
								<el-table-column prop="FirstWeightMoney" label="运费(元)" :key="7" v-if="item.FreightTemplateType"></el-table-column>
								<el-table-column prop="ContinueWeightToNumber" label="续重(KG)" :key="8" v-if="item.FreightTemplateType"></el-table-column>
								<el-table-column prop="ContinueWeightMoney" label="续费(元)" :key="9" v-if="item.FreightTemplateType"></el-table-column>

							</el-table>
								
							<el-table :data="item.NoDeliverList" border style="width: 100%.;margin-top:10px;" v-if="item.NoDeliverList[0].AreaNames&&item.NoDeliverList[0].AreaNames.length">
								<el-table-column prop="name" label="不配送区域" :key="10">
									<template slot-scope="scope">
										<el-tag :key="tag" v-for="(tag,index1) in scope.row.AreaNames" style="margin:0 10px 10px 10px"
										:disable-transitions="false">
											{{tag}}
										</el-tag>
									</template>
								</el-table-column>
							</el-table>
						</template>

					</el-card>
			</el-collapse-item>
		</el-collapse>
		<!-- 运费模板 -->

		<div v-if="!allData.length" style="margin:50px 0">暂无运费模板</div>



	</div>
</template>

<script>
	import {
		freightTemplateList,
		freightTemplatedeletet

	} from '@/api/goods'
	import buttonPermissions from '@/components/buttonPermissions';
	import { mapGetters } from 'vuex';
	export default {
		components: {
			buttonPermissions
		},
		computed: {
			...mapGetters([
				'IsDockingRoutuan'
			])
		},
		data() {
			return {
				searchKey: '',
				tableData: [],
				Total: 0,
				currentPage: 1,
				pageSize: 10,
				allData: [],
				showData: [],
				loading: false,

				opened: [],
				isShowTemplate: true
			}
		},
		created() {
			this.getList().then(()=> {
				this.opened = this.allData.map((t) => {
					return t.Id;
				});
			});

		}, 
		methods: {
			handleChange(val) {
				this.isShowTemplate = val.length !== this.allData.length
      },
			showTemplate() {
				this.isShowTemplate = !this.isShowTemplate
				if(!this.isShowTemplate) {
					this.opened  = []
				}else {
					this.opened  = this.allData.map((t) => {
						return t.Id;
					});
				}
			},
			handleFilter() {
				this.currentPage = 1;
				this.getList();
			},

			// 获取模板数据
			async getList() {
				this.loading = true;
				try {
					let data = {
						OrderBy: 'AddTime',
						IsAsc: true
					}
					let result = await freightTemplateList(data);
					this.allData = result.Result
					

					this.allData.map(t=>{
						t.NoDeliverList = [
							{
								AreaNames: t.NonDistributionAreaNames,
								AreaIds: t.NonDistributionAreaIds,
							}
						]
					})

				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}

			},

			// 添加运费模板
			async addTemptet(record) {
				this.$router.push({
					path: '/order/addTemplate',
					query: {
						templateId: record.Id ? record.Id : null
					}
				});


			},

			// 删除运费模板
			delecTemplate(record) {
				this.$confirm('删除后不可恢复，是否确认确认继续删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {

					this.loading = true;

					try {
						let result = await freightTemplatedeletet({
							Id: record.Id
						});

						if (result.IsSuccess) {
							this.$message({showClose: true,
								type: 'success',
								message: '删除成功'
							});
							if(this.opened.length) {
								this.opened.splice(this.opened.findIndex(item => item.Id === record.Id), 1);
							}
						}
					} catch (e) {
						console.log(e)
					} finally {
						setTimeout(() => {
							this.loading = false;
							this.getList();
						}, 100)
					}


				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				})
			},

			// 切换显示条数
			handleSizeChangenum(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;

				this.getList();


			},
			// 翻页
			handleCurrentChangenum(val) {
				// console.log(`当前页: ${val}`);

				this.currentPage = val;
				this.getList();
			},



		}
	}
</script>

<style lang="less">
	.shippingTemplates {
		width: 100%;
		background: #fff;
		padding: 30px;

		.header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 80%;
			.show-btn {
				cursor: pointer;
			}
		}

		.text {
			font-size: 14px;
		}

		.item {
			margin-bottom: 18px;
		}
		.template-collapse{
			margin-top: 20px;
			.template-collapse-item{
				width: 80%;
				overflow: hidden;
				.template-title{
					display: flex;
					width: 100%;
					flex-direction: row;
					justify-content: space-between;
				}
				
			}

		}
		// .clearfix:before,
		// .clearfix:after {
		// 	display: table;
		// 	content: "";
		// }

		// .clearfix:after {
		// 	clear: both
		// }

		.box-card {
			width: 100%;
		}
	}
</style>
